<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600px" scrollable>
      <v-card>
        <v-card-title>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <span class="text-h5">{{ modalTitle }}</span>
              <v-btn
                @click="handleModalClose(null)"
                style="float: right; cursor: pointer"
                icon
                color="#26223c"
              >
                <v-icon> mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="formContact" v-model="validContactForm">
              <v-row>
                <v-spacer></v-spacer>
                <v-col cols="12" sm="4" md="4">
                  <!-- @click="handleShowACactivityDialog()" -->
                  <v-btn
                    v-if="formModel.user_id"
                    color="primary"
                    elevation="2"
                    @click="handleOpenAC()"
                    >Contact Activity
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="formModel.christian_name"
                    label="First name*"
                    :rules="chnameRules"
                    :id="dynamicID"
                  ></v-text-field>
                </v-col>
                <!-- <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="formModel.christian_name2"
                    label="Middle name"
                    :id="dynamicID"
                  ></v-text-field>
                </v-col> -->
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="formModel.surname"
                    label="Last name*"
                    :rules="surnameRules"
                    :id="dynamicID"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Email*"
                    :rules="emailRules"
                    v-model="formModel.email"
                    :id="dynamicID"
                    :error-messages="messages['email']"
                    @keyup="messages['email'] = ''"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" v-if="formModel.contact_data">
                  <v-text-field
                    v-model="formModel.contact_data.phone_number"
                    label="Phone Number"
                    type="text"
                    :id="dynamicID"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" v-if="formModel.contact_data">
                  <v-text-field
                    v-model="formModel.contact_data.mobile_number"
                    label="Phone Number 2"
                    type="text"
                    :id="dynamicID"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-select
                    v-model="formModel.locale"
                    :items="languages"
                    item-text="name"
                    item-value="lang"
                    label="Language"
                    :rules="languageRules"
                    :id="dynamicID"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6">
                  <!-- <v-select
                    v-model="formModel.company_id"
                    :items="companyCollection"
                    label="Company"
                    item-text="name"
                    item-value="id"
                    :rules="companyRules"
                    :id="dynamicID"
                  /> -->
                  <!-- <v-autocomplete
                  v-model="autoCompleteItem"
                  :items="filteredItemCollection"
                  item-text="ReferenceName"
                  label="Search item"
                  return-object
                >
                </v-autocomplete>-->
                  <v-autocomplete
                    v-model="formModel.company_id"
                    :items="companyCollection"
                    label="Company"
                    item-text="name"
                    item-value="id"
                    :rules="companyRules"
                    :id="dynamicID"
                  />
                </v-col>

                <v-col cols="12" sm="6">
                  <v-checkbox
                    v-model="formModel.portal"
                    color="primary"
                    label="Portal"
                    hide-details
                  ></v-checkbox>
                  <v-select
                    class="mt-4"
                    v-model="formModel.google_rating"
                    :items="googleRatingCollection"
                    label="Google rating"
                    item-text="text"
                    item-value="value"
                  >
                    <template v-slot:item="{ item }">
                      <v-row align="center">
                        <span v-if="!item.value" color="primary">{{
                          item.text
                        }}</span>
                        <v-icon
                          v-for="value in item.value"
                          :key="value"
                          color="primary"
                        >
                          mdi-star</v-icon
                        >
                      </v-row>
                    </template>

                    <template v-slot:selection="{ item }">
                      <v-row align="center">
                        <span v-if="!item.value" color="primary">{{
                          item.text
                        }}</span>
                        <v-icon
                          v-for="value in item.value"
                          :key="value"
                          color="primary"
                        >
                          mdi-star</v-icon
                        >
                      </v-row>
                    </template>
                  </v-select>
                  <!-- <v-checkbox
                    v-model="formModel.rating_sent"
                    color="primary"
                    label="Customer sent a rating"
                    hide-details
                  ></v-checkbox> -->
                </v-col>
                <v-col cols="12" sm="6">
                  <v-checkbox
                    v-model="formModel.newsletter"
                    label="Newsletter"
                    color="indigo darken-3"
                    hide-details
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="handleModalClose(null)">
            Close
          </v-btn>
          <v-btn
            v-if="
              (permissionCanContact('create') && isNotEditedContact) ||
              permissionCanContact('update')
            "
            color="blue darken-1"
            text
            @click="handleSaveContactModalForm"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-overlay :value="loader">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-dialog>
    <ACActivityDialog
      :dialogData="ACDialogData"
      @handleCloseDialog="handleCloseACactivityDialog"
    ></ACActivityDialog>
    <v-snackbar v-model="snackbar" :timeout="2000">
      Successfully saved
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import i18nService from "@/core/services/i18n.service.js";
// const TRANSLATED_ATRIBUTES = ["name"];
import ApiService from "@/core/services/api.service";
import ACActivityDialog from "@/view/components/ACActivityDialog";
import { initialContactPersonData } from "./CompanyModalForm";
import Swal from "sweetalert2";

const TRANSLATED_ATRIBUTES = [];

export default {
  name: "ContactModalForm",
  // props: ["permissions", "modalData", "companyCollection"],
  props: ["permissions", "modalData"],
  components: { ACActivityDialog },
  data() {
    return {
      loader: false,
      snackbar: false,
      languages: i18nService.languages,
      // selectedLocale: i18nService.languages[0],
      // formModel: Object.assign({}, initialContactPersonData),
      formModel: Object.assign({}, initialContactPersonData),
      validContactForm: true,
      dialog: false,
      chnameRules: [
        (v) => !!v || "First name is required",
        (v) => (!!v && v.length > 2) || "First name must be min 3 characters",
      ],
      surnameRules: [
        (v) => !!v || "Last name is required",
        (v) => (!!v && v.length > 2) || "Last name must be min 3 characters",
      ],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      languageRules: [(v) => !!v || "Language is required"],
      ACDialogData: {
        show: false,
        contactID: null,
      },
      messages: {},
      googleRatingCollection: [
        { value: 0, text: "Select rating" },
        { value: 1, text: "1" },
        { value: 2, text: "2" },
        { value: 3, text: "3" },
        { value: 4, text: "4" },
        { value: 5, text: "5" },
      ],

      itemsWithHtml: [
        { text: "Option 1", html: "<strong>Option 1</strong>" },
        { text: "Option 2", html: "<em>Option 2</em>" },
        {
          text: "Option 3",
          html: '<span style="color: blue;">Option 3</span>',
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["companyCollection"]),

    modalTitle: function () {
      if (this.formModel && this.formModel.id) return "Edit contact";
      return "New contact";
    },
    dynamicID() {
      let text = "dynamicID";
      let chars = "abcdefghijklmnopqrstuvwxyz";

      for (let i = 0; i < 10; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length));
      }

      return text;
    },
  },
  watch: {
    modalData: {
      deep: true,
      handler(value) {
        this.resetErrorMessages();
        this.loader = true;
        this.fetchCompany().then(() => {
          if (!value.editedId) {
            this.formModel = Object.assign({}, initialContactPersonData);
            if (this.$refs.formContact) {
              this.$refs.formContact.resetValidation();
            }
          } else {
            this.editContactItem(value.editedId);
          }
          this.loader = false;
          this.dialog = value.dialog;
        });
      },
    },
  },
  methods: {
    ...mapActions(["fetchCompany"]),
    editContactItem(id) {
      ApiService.get("contact/contact/" + id)
        .then(({ data }) => {
          // data.contact_data = JSON.parse(data.contact_data);

          this.formModel = Object.assign({}, data);
          this.dialog = true;
        })
        .catch((error) => {
          console.log("Error!: ", error);
        });
    },

    handleSaveContactModalForm() {
      this.$refs.formContact.validate();
      if (this.validContactForm) {
        this.loader = true;
        if (!this.formModel.id) {
          //Create *************
          ApiService.post("contact/contact", this.formModel)
            .then(({ data }) => {
              this.snackbar = true;
              this.formModel.id = data.id;
              // this.handleModalClose(data.data.id);
              this.$emit("saveContactModal", this.formModel.id);
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors) {
                    this.setError(field, errors[field][0]);
                  }
                }
              }
            })
            .finally(() => {
              this.loader = false;
              this.snackbar = false;
            });
        } else {
          // Update *****************
          ApiService.put("contact/contact/" + this.formModel.id, this.formModel)
            .then(() => {
              this.snackbar = true;
              // this.handleModalClose(data.data.id);
              this.$emit("saveContactModal", this.formModel.id);
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors) {
                    this.setError(field, errors[field][0]);
                  }
                }
              }
            })
            .finally(() => {
              this.loader = false;
            });
        }
      }
    },

    handleModalClose(modalId) {
      this.$emit("closeModal", modalId);
    },

    resetErrorMessages() {
      let errorData = Object.assign({}, initialContactPersonData);
      this.languages.forEach((language) => {
        TRANSLATED_ATRIBUTES.forEach((attribute) => {
          errorData["translations." + language.lang + "." + attribute] = "";
        });
      });
      this.messages = errorData;
    },

    setError(prop, value) {
      this.messages[prop] = value;
    },

    permissionCanContact(action) {
      return this.permissions.find(
        (x) => x.name == "contacts.contact." + action
      );
    },

    handleShowACactivityDialog() {
      this.ACDialogData.contactID = this.formModel.user_id;
      this.ACDialogData.show = true;
    },

    handleCloseACactivityDialog() {
      // this.ACDialogData.show = false;
      // this.ACDialogData.email = null;

      let obj = {
        show: false,
        email: null,
      };
      this.ACDialogData = obj;
    },

    handleOpenAC() {
      this.loader = true;
      ApiService.get(`contact/contact/${this.formModel.user_id}/acId`).then(
        ({ data }) => {
          if (!data) {
            Swal.fire({
              title: "AC",
              text: "AC data not found!",
              icon: "error",
            });
          } else {
            window.open(
              `https://kismetal.activehosted.com/app/contacts/${data}`
            );
          }
          this.loader = false;
        }
      );
    },
  },
};
</script>
