<template>
  <div data-app>
    <v-card>
      <v-card-title>
        Contact List
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Search"
          single-line
          hide-details
          :id="dynamicID"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="collection"
        :search="search"
        :footer-props="{
          'items-per-page-options': [100, 200, 500, -1],
        }"
        :items-per-page="-1"
        :sort-by="['companyName']"
        :item-key="model + id"
        :loading="loading"
        loading-text="Loading... Please wait"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-btn
              v-if="permissionCanContact('create')"
              color="primary"
              dark
              class="mb-2"
              @click="handleNewContact"
            >
              New Contact
            </v-btn>

            <v-btn
              v-if="permissionCanCompany('create')"
              color="primary"
              dark
              class="mb-2"
              @click="handleNewCompany"
              style="margin-left: 20px"
            >
              New Company
            </v-btn>
          </v-toolbar>
        </template>

        <template #header="{ props: { headers } }">
          <thead class="v-data-table-header">
            <tr>
              <th></th>
              <th style="max-width: 150px">
                <v-select
                  v-model="billingCountryFilter"
                  :items="['Select', ...billingCountryCollection]"
                  dense
                ></v-select>
              </th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th style="max-width: 100px">
                <v-select
                  v-model="companyTypeFilter"
                  :items="['Select', ...typeCollection]"
                  item-text="name"
                  item-value="name"
                  dense
                  :id="dynamicID"
                ></v-select>
              </th>
            </tr>
          </thead>
        </template>

        <template
          v-slot:item.companyName="{ item }"
          v-if="permissionCanCompany('view')"
        >
          <span @click="editItem(item.companyId)" style="cursor: pointer">
            {{ item.companyName }}</span
          >
        </template>

        <template v-slot:item.billingCountries="{ item }">
          <span v-if="item.billingCountries">
            {{ item.billingCountries.toString() }}
          </span>
        </template>

        <template
          v-slot:item.contactName="{ item }"
          v-if="permissionCanContact('view')"
        >
          <span @click="editContactItem(item.id)" style="cursor: pointer">
            {{ item.contactName }}</span
          >
        </template>
        <template
          v-slot:item.actions="{ item }"
          v-if="permissionCanContact('delete')"
        >
          <!-- <router-link :to="`/contacts/supplierTypes/${item.id}`">
            <v-icon small class="mr-2"> mdi-pencil </v-icon>
          </router-link> -->
          <v-icon
            v-if="item.model == 'Contact' && item.contact_deletable"
            small
            @click="handleDeleteContact(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="dialogDelete" max-width="650px">
      <v-card>
        <v-card-title class="headline"
          >Are you sure you want to delete contact {{ contact2Delete.name }}?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDeleteCompany" max-width="650px">
      <v-card>
        <v-card-title class="headline"
          >Are you sure you want to delete company {{ company2Delete.name }}?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <CompanyModalForm
      :modalData="modalData"
      :permissions="permissions"
      :industryCollection="industryCollection"
      :qualificationCollection="qualificationCollection"
      :companyTypeCollection="companyTypeCollection"
      :companyCategoryCollection="companyCategoryCollection"
      :countryCollection="countryCollection"
      :supplierTypeCollection="supplierTypeCollection"
      :contactListCollection="contactListCollection"
      :statuses="statuses"
      :languages="languages"
      :defaultTab="0"
      @closeModalForm="handleCloseModalForm"
      @saveModalForm="handleSaveModalForm"
      @handleDeleteCompany="handleDeleteCompany"
      @handleFetchContactList="handleFetchContactList"
    ></CompanyModalForm>

    <ContactModalForm
      :permissions="permissions"
      :modalData="modalDataContact"
      :companyCollection="companyCollection"
      @closeModal="handleCloseContactModal"
    >
    </ContactModalForm>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ApiService from "@/core/services/api.service";
import i18nService from "@/core/services/i18n.service.js";
import CompanyModalForm, {
  initialContactPersonData,
  initialCompanyFormData,
} from "./CompanyModalForm";

import ContactModalForm from "./ContactModalForm.vue";

import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

// const ENDPOINT = "contact/all/";

// const initialContactFormData = {
//   contact_data: {
//     mobile_number: "",
//     phone_number: "",
//   },
//   christian_name: "",
//   christian_name2: "",
//   surname: "",
//   email: "",
//   language: "en",
//   company_id: 1,
// };

export default {
  name: "contactList",
  components: { CompanyModalForm, ContactModalForm },
  data() {
    return {
      showAll: false,
      validContactForm: true,
      search: "",
      companyTypeFilter: "Select",
      billingCountryFilter: "Select",
      collection: [],
      permissions: [],
      languages: i18nService.languages,
      loading: true,
      headers: [
        {
          text: "Company",
          value: "companyName",
        },
        {
          text: "Billing Country",
          value: "billingCountries",
          searchable: false,
          filter: (value) => {
            if (this.billingCountryFilter == "Select") return true;
            if (!value) return false;

            return value.find((item) => item == this.billingCountryFilter);
          },
        },
        {
          text: "Vat number",
          value: "vat_number",
        },
        {
          text: "Contact Name",
          value: "contactName",
        },
        {
          text: "Email",
          value: "email",
        },
        {
          text: "Phone Numbers",
          value: "phones",
        },
        {
          text: "Company Type",
          value: "companyTypeName",
          searchable: false,
          filter: (value) => {
            if (this.companyTypeFilter == "Select") return true;
            return value == this.companyTypeFilter;
          },
        },

        // { text: "Status", value: "status" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      // dialogContact: false,
      dialogDeleteCompany: false,
      company2Delete: {},
      contact2Delete: {},
      modalData: {
        dialog: false,
        editedIndex: null,
        editedItem: Object.assign({}, initialCompanyFormData()),
      },
      modalDataContact: {
        dialog: false,
        editedId: null,
      },
      contactFormData: {
        // editedItem: initialContactFormData,
        editedItem: Object.assign({}, initialContactPersonData),
      },
      dialogDelete: false,
      formValid: false,
      chnameRules: [
        (v) => !!v || "First name is required",
        (v) => v.length > 2 || "First name must be min 3 characters",
      ],
      surnameRules: [
        (v) => !!v || "Last name is required",
        (v) => v.length > 2 || "Last name must be min 3 characters",
      ],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      // languageRules: [(v) => !!v || "Language is required"],
      // companyRules: [(v) => !!v || "Company is required"],
      phoneRule: [
        (v) => !!v || "Phone field is required",
        (v) => /^\d+$/.test(v) || "Phone field only accept numbers",
        (v) => v.length > 8 || "Phone must be min 9 characters",
      ],
      phone2Rule: [
        // (v) => /^\d+$/.test(v) || "Phone field only accept numbers",
        // (v) => v.length > 8 || "Phone must be min 9 characters",
      ],
    };
  },

  computed: {
    ...mapGetters([
      "contactListCollection",
      "companyCollection",
      "companyTypeCollection",
      "industryCollection",
      "qualificationCollection",
      "companyCategoryCollection",
      "countryCollection",
      "supplierTypeCollection",
      "statuses",
    ]),

    isNotEditedContact() {
      if (
        this.contactFormData.editedItem &&
        this.contactFormData.editedItem.id
      ) {
        return 0;
      } else {
        return 1;
      }
    },
    dynamicID() {
      let text = "dynamicID";
      let chars = "abcdefghijklmnopqrstuvwxyz";

      for (let i = 0; i < 10; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length));
      }

      return text;
    },
    typeCollection() {
      return this.contactListCollection.map((item) => {
        return item.companyTypeName;
      });
    },

    billingCountryCollection() {
      if (!this.collection) return [];
      let billingCountryCollection = [];
      this.collection.forEach((item) => {
        if (
          item.companyAddresses &&
          item.companyAddresses.billing &&
          item.companyAddresses.billing.length > 0
        ) {
          item.companyAddresses.billing.forEach((addr) => {
            if (addr.country) {
              billingCountryCollection.push(addr.country);
            }
          });
        }
      });

      return [...new Set(billingCountryCollection)];
    },
  },

  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
    contactListCollection(val) {
      this.filterCollection(val);
    },
  },

  methods: {
    ...mapActions([
      "fetchContactList",
      "fetchCompany",
      "fetchCompanyType",
      "fetchIndustry",
      "fetchQualification",
      "fetchCompanyCategory",
      "fetchSupplierType",
      "fetchCountry",
    ]),
    editItem(id) {
      ApiService.get("contact/company/" + id)
        .then(({ data }) => {
          this.modalData.editedIndex = id;

          if (data.company_category) {
            data.company_category_id = data.company_category.id;
          }
          if (data.industry) {
            data.industry_id = data.industry.id;
          }
          if (data.types[0]) {
            data.company_type_id = data.types[0];
          }
          if (data.qualification) {
            data.qualification_id = data.qualification.id;
          }

          this.modalData.editedItem = Object.assign({}, data);
          this.modalData.dialog = true;
        })
        .catch((error) => {
          console.log("Error!: ", error);
        });
    },

    deleteItem() {
      this.modalData.editedIndex = null;
      this.dialogDelete = true;
    },

    handleNewCompany() {
      this.modalData = {
        dialog: true,
        editedIndex: null,
        editedItem: Object.assign({}, initialCompanyFormData()),
      };
    },

    deleteItemConfirm() {
      let urlToDelete = this.company2Delete.id
        ? "contact/company/" + this.company2Delete.id
        : "contact/contact/" + this.contact2Delete.id;

      // ApiService.delete("contact/company/" + this.company2Delete.id)
      ApiService.delete(urlToDelete)
        .then(() => {
          this.fetchContactList();
          this.fetchCompany();
          this.fetchCompanyType();
          this.fetchIndustry();
          this.fetchQualification();
          this.fetchCompanyCategory();
          this.fetchSupplierType();
          this.fetchCountry();
        })
        .catch((error) => {
          console.log("Error!: ", error);
        });
      this.closeDelete();
      this.handleCloseModalForm();
      this.fetchContactList();
    },

    handleCloseModalForm() {
      this.modalData.dialog = false;
      this.modalData.editedIndex = null;
      if (this.$route.name !== "contactList")
        this.$router.push({ name: "contactList" });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.dialogDeleteCompany = false;
      this.contact2Delete = {};
      this.company2Delete = {};
      this.$nextTick(() => {
        this.editedIndex = null;
      });
    },

    editContactItem(id) {
      this.modalDataContact.dialog = true;
      this.modalDataContact.editedId = id;
    },

    // handleSaveModalForm(editModalData) {
    handleSaveModalForm(companyId = null) {
      this.fetchContactList();
      this.fetchCompany();
      if (companyId) {
        this.editItem(companyId);
      }
      // if (editModalData.id) {
      //   this.editItem(editModalData.id);
      // }
      // else {
      //   this.handleCloseModalForm();
      // }
    },

    handleNewContact() {
      this.modalDataContact.editedId = null;
      this.modalDataContact.dialog = true;
    },

    handleDeleteCompany(model) {
      this.company2Delete = model;
      this.dialogDeleteCompany = true;
    },

    handleDeleteContact(model) {
      this.contact2Delete = model;
      this.dialogDelete = true;
    },

    filterCollection(coll) {
      // if (!this.showAll) {
      //   this.collection = coll;
      // } else {
      //   this.collection = coll.filter((item) => {
      //     return item.status == 1;
      //   });
      // }

      this.collection = coll.filter((item) => {
        if (
          this.permissionCanCompany("view") &&
          !this.permissionCanContact("view")
        ) {
          return item.model == "Company";
        }
        if (
          this.permissionCanContact("view") &&
          !this.permissionCanCompany("view")
        ) {
          return item.model == "Contact";
        }
        if (
          this.permissionCanContact("view") &&
          this.permissionCanCompany("view")
        ) {
          return item.model == "Contact" || item.model == "Company";
        }
      });
      this.loading = false;
      //this.collection = coll; return (item.model == 'Contact' || item.model == 'Company')
    },
    permissionCanCompany(action) {
      return this.permissions.find(
        (x) => x.name == "contacts.company." + action
      );
    },
    permissionCanContact(action) {
      return this.permissions.find(
        (x) => x.name == "contacts.contact." + action
      );
    },
    setPermissions() {
      if (localStorage.permissions) {
        this.permissions = JSON.parse(localStorage.permissions).filter(
          (item) =>
            item.name.indexOf("company") > -1 ||
            item.name.indexOf("contact") > -1
        );
      }
    },

    handleFetchContactList() {
      this.fetchContactList();
    },

    handleCloseContactModal() {
      this.fetchContactList();
      this.modalDataContact.editedId = null;
      this.modalDataContact.dialog = false;
    },
  },

  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Contact List" }]);
    this.fetchContactList();
    // this.fetchCompany();
    this.fetchCompanyType();
    this.fetchIndustry();
    this.fetchQualification();
    this.fetchCompanyCategory();
    this.fetchSupplierType();
    this.fetchCountry();
    this.setPermissions();
  },
};
</script>
