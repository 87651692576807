<template>
  <v-dialog v-model="modalData.dialog" persistent max-width="1500" scrollable>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <span class="headline">{{ formTitle }} </span>
            <span v-if="formModel.id"> - {{ formModel.name }}</span>
            <v-btn
              @click="handleCloseModalForm"
              style="float: right; cursor: pointer"
              icon
              color="#26223c"
            >
              <v-icon> mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-form v-model="formValid" ref="form" autocomplete="off">
          <v-row>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="2" md="2">
              <v-select
                v-if="statuses.system"
                v-model="formModel.status"
                :items="statuses.contacts.company"
                label="Status"
                item-text="value"
                item-value="key"
                :id="dynamicID"
              />
            </v-col>
            <v-col
              v-if="formModel.id && formModel.deletable"
              cols="12"
              sm="2"
              md="2"
            >
              <v-btn depressed color="error" @click="handleDeleteCompany">
                Delete Company
              </v-btn>
            </v-col>
          </v-row>
          <v-container style="min-height: 550px">
            <v-row>
              <v-tabs vertical v-model="defaultTab">
                <v-tab>
                  <v-icon left>mdi-tune</v-icon>
                  Company details
                </v-tab>
                <v-tab :disabled="!formModel.id">
                  <v-icon left>mdi-account-multiple</v-icon>
                  Contact persons
                </v-tab>
                <v-tab>
                  <v-icon left>mdi-coin</v-icon>
                  Billing addresses
                </v-tab>
                <v-tab>
                  <v-icon left>mdi-truck-delivery</v-icon>
                  Shipping addresses
                </v-tab>

                <v-tab-item>
                  <v-card flat>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            v-model="formModel.name"
                            label="Name*"
                            :rules="nameRules"
                            :id="dynamicID"
                            :error-messages="messages['name']"
                            @keyup="messages['name'] = ''"
                          ></v-text-field>
                          <v-select
                            v-model="formModel.company_type_id"
                            :items="companyTypeCollection"
                            item-text="name"
                            item-value="id"
                            label="Company type*"
                            :rules="companyTypeRules"
                          ></v-select>
                          <v-select
                            v-if="formModel.company_type_id == 3"
                            v-model="formModel.supplierTypes"
                            :items="supplierTypeCollection"
                            item-text="name"
                            item-value="id"
                            label="Supplier type"
                            :id="dynamicID"
                            multiple
                          ></v-select>
                          <v-select
                            v-model="formModel.company_category_id"
                            :items="companyCategoryCollection"
                            item-text="name"
                            item-value="id"
                            label="Company category"
                            :rules="companyCategoryRules"
                            :id="dynamicID"
                          ></v-select>
                          <v-select
                            v-model="formModel.industry_id"
                            :items="industryCollection"
                            item-text="name"
                            item-value="id"
                            label="Industry"
                            :rules="industryRules"
                            :id="dynamicID"
                          ></v-select>
                          <v-select
                            v-model="formModel.qualification_id"
                            :items="qualificationCollection"
                            item-text="name"
                            item-value="id"
                            label="Qualification"
                            :id="dynamicID"
                          ></v-select>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            v-model="formModel.vat_number"
                            label="Tax number"
                            :id="dynamicID"
                            :error-messages="messages['vat_number']"
                            @keyup="messages['vat_number'] = ''"
                          ></v-text-field>
                          <v-text-field
                            v-model="formModel.bank_account"
                            label="Bank account number"
                            :rules="bankAccountNumberRules"
                            :id="dynamicID"
                          ></v-text-field>
                          <v-text-field
                            v-model="formModel.iban"
                            label="IBAN"
                            :rules="ibanRules"
                            :id="dynamicID"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <!-- <v-text-field
                            v-model="formModel.addresses.hq.name"
                            label="Headquarter"
                            :rules="headquarterRules"
                          ></v-text-field> -->
                          <h5>Headquarter</h5>

                          <v-select
                            v-model="formModel.addresses.hq.country"
                            :items="countryCollection"
                            label="Country"
                            item-text="name"
                            item-value="id"
                            :id="dynamicID"
                          />

                          <v-text-field
                            v-model="formModel.addresses.hq.county"
                            label="County"
                            :rules="countyRules"
                          ></v-text-field>

                          <v-text-field
                            v-model="formModel.addresses.hq.postcode"
                            label="Postcode"
                            :id="dynamicID"
                          ></v-text-field>

                          <v-text-field
                            v-model="formModel.addresses.hq.city"
                            label="City"
                            :id="dynamicID"
                          ></v-text-field>
                          <v-text-field
                            v-model="formModel.addresses.hq.street"
                            label="Street"
                            :id="dynamicID"
                          ></v-text-field>
                          <v-row v-if="!formModel.id">
                            <v-col cols="6">
                              <v-checkbox
                                v-model="formModel.billingAddress"
                                color="primary"
                                label="Billing address"
                                hide-details
                                false-value="0"
                                true-value="1"
                              ></v-checkbox>
                            </v-col>
                            <v-col cols="6">
                              <v-checkbox
                                v-model="formModel.shippingAddress"
                                color="primary"
                                label="Shipping address"
                                hide-details
                                false-value="0"
                                true-value="1"
                              ></v-checkbox>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col cols="12">
                          <v-textarea
                            outlined
                            v-model="formModel.comment"
                            label="Comment"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item>
                  <v-card flat>
                    <v-card-text>
                      <v-form
                        ref="contactPersonForm"
                        v-model="validContactPersonForm"
                      >
                        <v-row>
                          <v-col cols="1">
                            <v-text-field
                              v-model="editedContactPerson.christian_name"
                              label="First Name"
                              :rules="christianNameRules"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="1">
                            <v-text-field
                              v-model="editedContactPerson.christian_name2"
                              label="Middle Name"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="1">
                            <v-text-field
                              v-model="editedContactPerson.surname"
                              label="Last Name"
                              :rules="surNameRules"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="2">
                            <v-text-field
                              v-model="editedContactPerson.email"
                              label="Email"
                              :rules="emailRules"
                              :error-messages="messages['email']"
                              @keyup="messages['email'] = ''"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="1">
                            <v-text-field
                              v-model="
                                editedContactPerson.contact_data.phone_number
                              "
                              label="Phone Number"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="1">
                            <v-select
                              v-model="editedContactPerson.locale"
                              :items="languages"
                              item-text="name"
                              item-value="lang"
                              label="Language*"
                              :error-messages="messages['locale']"
                              @change="messages['locale'] = ''"
                            ></v-select>
                          </v-col>
                          <v-col cols="1">
                            <v-checkbox
                              v-model="editedContactPerson.portal"
                              color="primary"
                              label="Portal"
                              hide-details
                            ></v-checkbox>
                          </v-col>
                          <v-col cols="2">
                            <v-checkbox
                              v-model="editedContactPerson.newsletter"
                              label="Newsletter"
                              color="indigo darken-3"
                              hide-details
                            ></v-checkbox>
                          </v-col>
                          <v-col cols="2">
                            <v-btn
                              color="ma-2 success"
                              @click="handleSaveContactPerson"
                            >
                              Add
                            </v-btn>
                            <v-btn
                              class="ma-2"
                              @click="handleCancelContactPerson"
                              :disabled="!editedContactPerson.id"
                            >
                              Cancel
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-form>
                      <v-row>
                        <v-col cols="12">
                          <v-card>
                            <v-card-title>
                              <v-text-field
                                v-model="searchContact"
                                append-icon="mdi-magnify"
                                label="Search"
                                single-line
                                hide-details
                                :id="dynamicID"
                              ></v-text-field>
                            </v-card-title>
                            <v-data-table
                              :headers="contactsHeaders"
                              :items="formModel.contacts"
                              :search="searchContact"
                            >
                              <template v-slot:item.newsletter="{ item }">
                                <v-simple-checkbox
                                  v-model="item.newsletter"
                                  disabled
                                ></v-simple-checkbox>
                              </template>
                              <template v-slot:item.portal="{ item }">
                                <v-simple-checkbox
                                  v-model="item.portal"
                                  disabled
                                ></v-simple-checkbox>
                              </template>
                              <template v-slot:item.phone_number="{ item }">
                                <span>{{
                                  item.contact_data.phone_number
                                }}</span>
                              </template>
                              <template v-slot:item.actions="{ item }">
                                <v-icon
                                  small
                                  class="mr-2"
                                  @click="handleEditContact(item)"
                                  :disabled="editedContactPerson.id"
                                  v-if="permissionCanContact('view')"
                                >
                                  mdi-pencil
                                </v-icon>

                                <v-icon
                                  small
                                  @click="handleDeleteContactItem(item.id)"
                                  v-if="permissionCanContact('delete')"
                                >
                                  mdi-delete
                                </v-icon>
                              </template>
                            </v-data-table>
                          </v-card>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-textarea
                            outlined
                            v-model="formModel.addresses.contactPersonComment"
                            label="Comment"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item>
                  <v-card flat>
                    <v-card-text>
                      <v-form
                        ref="billingAddressForm"
                        v-model="validBillingAddressForm"
                      >
                        <v-row>
                          <v-col cols="12">
                            <v-simple-table height="350">
                              <template v-slot:default>
                                <thead>
                                  <tr>
                                    <th class="text-left">
                                      <v-text-field
                                        v-model="editedBillingAddress.postcode"
                                        label="Postcode"
                                        :rules="postcodeRules"
                                        :id="dynamicID"
                                      ></v-text-field>
                                    </th>
                                    <th class="text-left">
                                      <v-select
                                        v-model="editedBillingAddress.country"
                                        :items="countryCollection"
                                        label="Country"
                                        item-text="name"
                                        item-value="id"
                                        :rules="countryRules"
                                        :id="dynamicID"
                                      />
                                    </th>
                                    <th class="text-left">
                                      <v-text-field
                                        v-model="editedBillingAddress.county"
                                        label="County"
                                        :rules="countyRules"
                                        :id="dynamicID"
                                      ></v-text-field>
                                    </th>
                                    <th class="text-left">
                                      <v-text-field
                                        v-model="editedBillingAddress.city"
                                        label="City"
                                        :rules="cityRules"
                                        :id="dynamicID"
                                      ></v-text-field>
                                    </th>
                                    <th class="text-left">
                                      <v-text-field
                                        v-model="editedBillingAddress.street"
                                        label="Street"
                                        :rules="streetRules"
                                        :id="dynamicID"
                                      ></v-text-field>
                                    </th>
                                    <th class="text-left">
                                      <v-btn
                                        class="ma-2"
                                        color="success"
                                        @click="handleSaveBillingAddress"
                                      >
                                        Add billing address
                                      </v-btn>
                                      <v-btn
                                        class="ma-2"
                                        @click="handleCancelBillingAddressData"
                                        :disabled="!cancelBillingAddressData"
                                      >
                                        Cancel
                                      </v-btn>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="(item, key) in formModel.addresses
                                      .billing"
                                    :key="key"
                                  >
                                    <td>{{ item.postcode }}</td>
                                    <td>{{ item.country }}</td>
                                    <td>{{ item.county }}</td>
                                    <td>{{ item.city }}</td>
                                    <td>{{ item.street }}</td>
                                    <td>
                                      <v-icon
                                        small
                                        class="mr-2"
                                        @click="
                                          handleEditBillingAddressItem(
                                            item,
                                            key
                                          )
                                        "
                                        :disabled="cancelBillingAddressData"
                                      >
                                        mdi-pencil
                                      </v-icon>

                                      <v-icon
                                        small
                                        @click="
                                          handleDeleteAddressItem(
                                            'BillingAddress',
                                            key
                                          )
                                        "
                                      >
                                        mdi-delete
                                      </v-icon>
                                      <v-btn
                                        v-if="checkAddToShipping(item)"
                                        elevation="2"
                                        small
                                        class="ml-4"
                                        @click="handleAddToShipping(item)"
                                      >
                                        Add to shipping
                                      </v-btn>
                                    </td>
                                  </tr>
                                </tbody>
                              </template>
                            </v-simple-table>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-textarea
                              outlined
                              v-model="formModel.addresses.billingComment"
                              label="Comment"
                            ></v-textarea>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item>
                  <v-card flat>
                    <v-card-text>
                      <v-form
                        ref="shippingAddressForm"
                        v-model="validShippingAddressForm"
                      >
                        <v-row>
                          <v-col cols="12">
                            <v-simple-table height="350">
                              <template v-slot:default>
                                <thead>
                                  <tr>
                                    <th class="text-left">
                                      <v-text-field
                                        v-model="editedShippingAddress.postcode"
                                        label="Postcode"
                                        :rules="postcodeRules"
                                        :id="dynamicID"
                                      ></v-text-field>
                                    </th>
                                    <th class="text-left">
                                      <v-select
                                        v-model="editedShippingAddress.country"
                                        :items="countryCollection"
                                        label="Country"
                                        item-text="name"
                                        item-value="id"
                                        :rules="countryRules"
                                        :id="dynamicID"
                                      />
                                    </th>
                                    <th class="text-left">
                                      <v-text-field
                                        v-model="editedShippingAddress.county"
                                        label="County"
                                        :rules="countyRules"
                                      ></v-text-field>
                                    </th>
                                    <th class="text-left">
                                      <v-text-field
                                        v-model="editedShippingAddress.city"
                                        label="City"
                                        :rules="cityRules"
                                        :id="dynamicID"
                                      ></v-text-field>
                                    </th>
                                    <th class="text-left">
                                      <v-text-field
                                        v-model="editedShippingAddress.street"
                                        label="Street"
                                        :rules="streetRules"
                                        :id="dynamicID"
                                      ></v-text-field>
                                    </th>
                                    <th class="text-left">
                                      <v-btn
                                        color="success"
                                        @click="handleSaveShippingAddress"
                                      >
                                        Add shipping address
                                      </v-btn>
                                      <v-btn
                                        class="ma-2"
                                        @click="handleCancelShippingAddressData"
                                        :disabled="!cancelShippingAddressData"
                                      >
                                        Cancel
                                      </v-btn>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="(item, key) in formModel.addresses
                                      .shipping"
                                    :key="key"
                                  >
                                    <td>{{ item.postcode }}</td>
                                    <td>{{ item.country }}</td>
                                    <td>{{ item.county }}</td>
                                    <td>{{ item.city }}</td>
                                    <td>{{ item.street }}</td>
                                    <td>
                                      <v-icon
                                        small
                                        class="mr-2"
                                        @click="
                                          handleEditShippingAddressItem(
                                            item,
                                            key
                                          )
                                        "
                                        :disabled="cancelShippingAddressData"
                                      >
                                        mdi-pencil
                                      </v-icon>

                                      <v-icon
                                        small
                                        @click="
                                          handleDeleteAddressItem(
                                            'ShippingAddress',
                                            key
                                          )
                                        "
                                      >
                                        mdi-delete
                                      </v-icon>
                                      <v-btn
                                        v-if="checkAddToBilling(item)"
                                        elevation="2"
                                        small
                                        class="ml-4"
                                        @click="handleAddToBilling(item)"
                                      >
                                        Add to billing
                                      </v-btn>
                                    </td>
                                  </tr>
                                </tbody>
                              </template>
                            </v-simple-table>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-textarea
                              outlined
                              v-model="formModel.addresses.shippingComment"
                              label="Comment"
                            ></v-textarea>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="handleCloseModalForm">
          Cancel
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="handleSaveModalForm"
          v-if="
            (permissionCanCompany('create') &&
              this.modalData.editedIndex === null) ||
            permissionCanCompany('update')
          "
          :disabled="cancelShippingAddressData || cancelBillingAddressData"
        >
          Save
        </v-btn>
      </v-card-actions>

      <v-dialog v-model="deleteModalData.dialog" max-width="500px">
        <v-card>
          <v-card-title class="headline"
            >Are you sure you want to delete this item?</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDelete"
              >Cancel</v-btn
            >
            <v-btn color="blue darken-1" text @click="handleDeleteItemConfirm"
              >OK</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
    <v-overlay :value="loader">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-snackbar v-model="snackbar" :timeout="2000">
      Successfully saved
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-dialog>
</template>

<script>
// import { mapGetters, mapActions } from "vuex";
import i18nService from "@/core/services/i18n.service.js";
// const TRANSLATED_ATRIBUTES = ["name"];
import ApiService from "@/core/services/api.service";

export const initialCompanyFormData = () => ({
  id: null,
  company_category_id: null,
  industry_id: null,
  iban: "",
  contacts: [],
  vat_number: "",
  bank_account: "",
  name: "",
  comment: "",
  supplierTypes: [],
  shippingAddress: 0,
  billingAddress: 0,
  addresses: {
    contactPersonComment: "",
    billingComment: "",
    hq: {
      postcode: "",
      city: "",
      street: "",
      country: "HU",
      county: "",
    },
    billing: [],
    shippingComment: "",
    shipping: [],
  },
  // contact_data: {},
  // company_type_id: "",
  types: [1],
  status: 1,
  email: null,
});
/*
const initialCompanyFormData1 = {
  id: null,
  company_category_id: null,
  industry_id: null,
  iban: "",
  contacts: [],
  vat_number: "",
  bank_account: "",
  name: "",
  comment: "",
  supplierTypes: [],
  shippingAddress: 0,
  billingAddress: 0,
  addresses: {
    contactPersonComment: "",
    billingComment: "",
    hq: {
      postcode: "",
      city: "",
      street: "",
      country: null,
    },
    billing: [],
    shippingComment: "",
    shipping: [],
  },
  // contact_data: {},
  // company_type_id: "",
  types: [1],
  status: 1,
};
*/
export const initialContactPersonData = {
  id: null,
  contact_data: {
    mobile_number: "",
    phone_number: "",
  },
  // contact_data: '{"phone_number": null, "mobile_number": null}',
  christian_name: "",
  christian_name2: "",
  status: 1,
  surname: "",
  email: "",
  language_id: "en",
  company_id: null,
  newsletter: 0,
  portal: 0,
  google_rating: 0,
};

const initialBillingAddressData = {
  postcode: "",
  country: "HU",
  county: "",
  city: "",
  street: "",
};

const initialDeleteModalData = {
  dialog: false,
  type: null,
  key: null,
};

const TRANSLATED_ATRIBUTES = [];

export default {
  name: "CompanyForm",
  props: [
    "defaultTab",
    "permissions",
    "modalData",
    "industryCollection",
    "qualificationCollection",
    "companyTypeCollection",
    "companyCategoryCollection",
    "statuses",
    "locale",
    "countryCollection",
    "supplierTypeCollection",
  ],
  data() {
    return {
      loader: false,
      snackbar: false,
      languages: i18nService.languages,
      // selectedLocale: i18nService.languages[0],
      formModel: { ...initialCompanyFormData() },
      formValid: false,
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => v.length > 2 || "First name must be min 3 characters",
      ],
      christianNameRules: [
        (v) => !!v || "First name is required",
        (v) => v.length > 2 || "First name must be min 3 characters",
      ],
      surNameRules: [
        (v) => !!v || "Last name is required",
        (v) => v.length > 1 || "Last name must be min 2 characters",
      ],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      languageRules: [(v) => !!v || "Language is required"],
      phoneRule: [
        (v) => !!v || "Phone field is required",
        (v) => /^\d+$/.test(v) || "Phone field only accept numbers",
        (v) => v.length > 8 || "Phone must be min 9 characters",
      ],
      taxNumberRules: [
        (v) => !!v || "Tax number is required",
        // (v) => v.length > 2 || "Headquarter must be min 3 characters",
      ],
      bankAccountNumberRules: [
        //(v) => !!v || "Bank account numbber is required",
        // (v) => v.length > 2 || "Headquarter must be min 3 characters",
      ],
      ibanRules: [
        //(v) => !!v || "IBAN is required",
        // (v) => v.length > 2 || "Headquarter must be min 3 characters",
      ],

      countyRules: [(v) => !v || (v && v.length <= 2) || "Max 2 characters"],

      postcodeRules: [
        (v) => !!v || "Postcode is required",
        (v) => v.length > 3 || "Postcode must be min 4 characters",
      ],
      countryRules: [(v) => !!v || "Country is required"],
      cityRules: [
        (v) => !!v || "City is required",
        (v) => v.length > 2 || "City must be min 3 characters",
      ],
      streetRules: [
        (v) => !!v || "Street is required",
        (v) => v.length > 2 || "Street must be min 3 characters",
      ],
      companyRules: [(v) => !!v || "Company is required"],
      companyTypeRules: [(v) => !!v || "Company type is required"],
      //companyCategoryRules: [(v) => !!v || "Company category is required"],
      //industryRules: [(v) => !!v || "Industry is required"],

      editedContactPerson: Object.assign({}, initialContactPersonData),
      cancelledContactPerson: Object.assign({}, initialContactPersonData),
      validContactPersonForm: true,

      editedBillingAddress: Object.assign({}, initialBillingAddressData),
      validBillingAddressForm: true,
      cancelBillingAddressData: null,
      cancelShippingAddressData: null,

      editedShippingAddress: Object.assign({}, initialBillingAddressData),
      validShippingAddressForm: true,
      messages: {},
      deleteModalData: Object.assign({}, initialDeleteModalData),

      searchContact: "",
      contactsHeaders: [
        { text: "First Name", value: "christian_name" },
        { text: "Middle Name", value: "christian_name2" },
        { text: "Last Name", value: "surname" },
        // { text: 'Company', value: 'company_id' },
        { text: "Email", value: "email" },
        { text: "phone Number", value: "phone_number" },
        { text: "Locale", value: "locale" },
        { text: "Portal", value: "portal" },
        { text: "Newsletter", value: "newsletter" },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },
  computed: {
    // ...mapGetters([CURRENCY_COLLECTION]),

    formTitle() {
      return this.modalData.editedIndex === null
        ? "New Company"
        : "Edit Company";
    },
    dynamicID() {
      let text = "dynamicID";
      let chars = "abcdefghijklmnopqrstuvwxyz";

      for (let i = 0; i < 10; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length));
      }

      return text;
    },
  },
  /*
  watch: {
    modalData: {
      deep: true,
      handler(value) {
        this.formModel = value.editedItem;
        this.resetErrorMessages();
        if (!value.editedItem.id) {
          this.editedBillingAddress = Object.assign(
            {},
            initialBillingAddressData
          );
          this.validBillingAddressForm = true;
          this.editedShippingAddress = Object.assign(
            {},
            initialBillingAddressData
          );
          this.validShippingAddressForm = true;
          if (this.$refs.form) {
            this.$refs.form.resetValidation();
          }
        }
      },
    },
  },
*/

  watch: {
    "modalData.editedIndex": {
      deep: true,
      handler(value) {
        this.resetErrorMessages();

        if (!value) {
          this.editedBillingAddress = Object.assign(
            {},
            initialBillingAddressData
          );
          this.validBillingAddressForm = true;
          this.editedShippingAddress = Object.assign(
            {},
            initialBillingAddressData
          );
          this.validShippingAddressForm = true;

          if (this.$refs.form) {
            this.$refs.form.resetValidation();
          }
        } else {
          if (this.formModel.id != this.modalData.editedItem.id) {
            this.formModel = Object.assign({}, this.modalData.editedItem);
          }
        }
      },
    },

    "modalData.dialog": {
      deep: true,
      handler(value) {
        // if (value && !this.modalData.editedItem.id) {
        this.editedContactPerson = Object.assign({}, initialContactPersonData);
        this.editedContactPerson.contact_data.mobile_number = "";
        this.editedContactPerson.contact_data.phone_number = "";
        if (value) {
          this.formModel = Object.assign({}, this.modalData.editedItem);
        }
      },
    },
  },

  methods: {
    // ...mapActions([FETCH_CURRENCY]),
    handleCloseModalForm(modalId) {
      this.defaultTab = 0;
      // console.log("close");
      // this.editedContactPerson = Object.assign({}, initialContactPersonData);
      this.$emit("closeModalForm", modalId);
    },
    handleSaveModalForm() {
      this.$refs.form.validate();
      if (this.formValid) {
        this.resetErrorMessages();
        this.loader = true;
        let matchBilling = this.formModel.addresses.billing.filter((item) => {
          return (
            item.city &&
            item.city === this.formModel.addresses.hq.city &&
            item.county &&
            item.county === this.formModel.addresses.hq.county &&
            item.street &&
            item.street === this.formModel.addresses.hq.street &&
            item.postcode &&
            item.postcode === this.formModel.addresses.hq.postcode
          );
        });
        if (
          this.formModel.billingAddress &&
          matchBilling.length == 0 &&
          !this.formModel.id
        ) {
          this.formModel.addresses.billing.push(this.formModel.addresses.hq);
        }
        let matchShipping = this.formModel.addresses.shipping.filter((item) => {
          return (
            item.city &&
            item.city === this.formModel.addresses.hq.city &&
            item.county &&
            item.county === this.formModel.addresses.hq.county &&
            item.street &&
            item.street === this.formModel.addresses.hq.street &&
            item.postcode &&
            item.postcode === this.formModel.addresses.hq.postcode
          );
        });
        if (
          (this.formModel.shippingAddress == matchShipping.length) == 0 &&
          !this.formModel.id
        ) {
          this.formModel.addresses.shipping.push(this.formModel.addresses.hq);
        }

        if (this.formModel.company_type_id != 3) {
          this.formModel.supplierTypes = [];
        } else {
          this.formModel.supplierTypes = this.formModel.supplierTypes.filter(
            function (e) {
              return e != null;
            }
          );
        }

        let model = Object.assign({}, this.formModel);
        model.types = [model.company_type_id];
        model.contact_data = [];
        if (this.modalData.editedItem.id) {
          ApiService.put("contact/company/" + model.id, model)
            .then((data) => {
              this.snackbar = true;
              this.$emit("saveModalForm", Object.assign({}, data));
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors) {
                    this.setError(field, errors[field][0]);
                  }
                }
              }
            })
            .finally(() => {
              this.loader = false;
            });
        } else {
          //create model
          ApiService.post("contact/company", model)
            .then(({ data }) => {
              this.snackbar = true;

              this.$emit("saveModalForm", data.id);
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors) {
                    this.setError(field, errors[field][0]);
                  }
                }
              }
            })
            .finally(() => {
              this.loader = false;
            });
        }
      }
    },

    handleSaveContactPerson() {
      this.$refs.contactPersonForm.validate();
      if (this.validContactPersonForm) {
        this.editedContactPerson.company_id = this.formModel.id;
        this.loader = false;
        if (!this.editedContactPerson.id) {
          ApiService.post("contact/contact", this.editedContactPerson)
            .then(() => {
              this.snackbar = true;
              initialContactPersonData.contact_data = {
                mobile_number: "",
                phone_number: "",
              };
              this.editedContactPerson = Object.assign(
                {},
                initialContactPersonData
              );
              this.cancelledContactPerson = Object.assign(
                {},
                initialContactPersonData
              );

              // this.$refs.contactPersonForm.resetValidation();
              this.reloadFormData(this.formModel.id, true);
            })
            .catch((error) => {
              // this.$refs.contactPersonForm.validate();
              this.$nextTick(() => {
                if (error.response) {
                  let errors = error.response.data;
                  if (errors) {
                    for (let field in errors) {
                      this.setError(field, errors[field][0]);
                    }
                  }
                }
              });
            })
            .finally(() => {
              this.loader = false;
            });
        } else {
          ApiService.put(
            "contact/contact/" + this.editedContactPerson.id,
            this.editedContactPerson
          )
            .then(() => {
              this.snackbar = true;
              initialContactPersonData.contact_data = {
                mobile_number: "",
                phone_number: "",
              };
              this.editedContactPerson = Object.assign(
                {},
                initialContactPersonData
              );
              this.cancelledContactPerson = Object.assign(
                {},
                initialContactPersonData
              );

              // this.$refs.contactPersonForm.resetValidation();
              this.reloadFormData(this.formModel.id, true);
            })
            .catch((error) => {
              // console.log("error put");
              // this.$refs.contactPersonForm.validate();
              this.$nextTick(() => {
                if (error.response) {
                  let errors = error.response.data;
                  if (errors) {
                    for (let field in errors) {
                      this.setError(field, errors[field][0]);
                    }
                  }
                }
              });
            })
            .finally(() => {
              this.loader = false;
            });
        }
        this.dialogContact = false;
        // this.$emit("saveModalForm", Object.assign({}, this.formModel));
      }
    },

    handleCancelContactPerson() {
      this.editedContactPerson = Object.assign({}, this.cancelledContactPerson);
      this.handleSaveContactPerson();
    },

    reloadFormData(id, saveContacts = null) {
      this.loader = true;
      let oldModalForm = Object.assign({}, this.formModel);
      this.formModel.contacts = [];
      ApiService.get("contact/company/" + id)
        .then(({ data }) => {
          // data.addresses = data.addresses;
          if (data.company_category) {
            data.company_category_id = data.company_category.id;
          }
          if (data.industry) {
            data.industry_id = data.industry.id;
          }
          if (data.types[0]) {
            data.company_type_id = data.types[0];
          }

          if (data.qualification) {
            data.qualification_id = data.qualification.id;
          }
          if (saveContacts) {
            oldModalForm.contacts = data.contacts;
            this.formModel = Object.assign({}, oldModalForm);
          } else {
            this.formModel = Object.assign({}, data);
          }
        })
        .finally(() => {
          this.loader = false;
        });
    },

    handleEditBillingAddressItem(item, key) {
      this.editedBillingAddress = Object.assign({}, item);
      this.cancelBillingAddressData = Object.assign({}, item);
      this.formModel.addresses.billing.splice(key, 1);
    },

    handleEditShippingAddressItem(item, key) {
      this.editedShippingAddress = Object.assign({}, item);
      this.cancelShippingAddressData = Object.assign({}, item);
      this.formModel.addresses.shipping.splice(key, 1);
    },

    handleDeleteAddressItem(type, key) {
      this.deleteModalData.dialog = true;
      this.deleteModalData.key = key;
      this.deleteModalData.type = type;
    },

    handleDeleteContactItem(key) {
      this.deleteModalData.dialog = true;
      this.deleteModalData.key = key;
      this.deleteModalData.type = "contact";
    },

    handleDeleteItemConfirm() {
      if (this.deleteModalData.type == "BillingAddress") {
        this.formModel.addresses.billing.splice(this.deleteModalData.key, 1);
      }
      if (this.deleteModalData.type == "ShippingAddress") {
        this.formModel.addresses.shipping.splice(this.deleteModalData.key, 1);
      }
      if (this.deleteModalData.type == "contact") {
        ApiService.delete("contact/contact/" + this.deleteModalData.key)
          .then(() => {
            this.reloadFormData(this.formModel.id);
          })
          .catch((error) => {
            console.log("Error!: ", error);
          });
      }
      this.closeDelete();
    },

    closeDelete() {
      this.deleteModalData = Object.assign({}, initialDeleteModalData);
    },

    handleSaveBillingAddress() {
      this.$refs.billingAddressForm.validate();
      if (this.validBillingAddressForm) {
        this.formModel.addresses.billing.push(this.editedBillingAddress);
        this.editedBillingAddress = Object.assign(
          {},
          initialBillingAddressData
        );
        this.$refs.billingAddressForm.resetValidation();
        this.cancelBillingAddressData = null;
      }
    },

    handleSaveShippingAddress() {
      this.$refs.shippingAddressForm.validate();
      if (this.validShippingAddressForm) {
        this.formModel.addresses.shipping.push(this.editedShippingAddress);
        this.editedShippingAddress = Object.assign(
          {},
          initialBillingAddressData
        );
        this.$refs.shippingAddressForm.resetValidation();
        this.cancelShippingAddressData = null;
      }
    },

    handleEditContact(item) {
      let model = Object.assign({}, item);
      // model.contact_data = model.contact_data;
      this.editedContactPerson = Object.assign({}, model);
      this.cancelledContactPerson = Object.assign({}, model);
    },

    handleDeleteCompany() {
      this.$emit("handleDeleteCompany", this.formModel);
    },

    handleCancelBillingAddressData() {
      this.formModel.addresses.billing.push(this.cancelBillingAddressData);
      this.cancelBillingAddressData = null;
      this.editedBillingAddress = Object.assign({}, initialBillingAddressData);
      this.$refs.billingAddressForm.resetValidation();
    },

    handleCancelShippingAddressData() {
      this.formModel.addresses.shipping.push(this.cancelShippingAddressData);
      this.cancelShippingAddressData = null;
      this.editedShippingAddress = Object.assign({}, initialBillingAddressData);
      this.$refs.shippingAddressForm.resetValidation();
    },

    resetErrorMessages() {
      let errorData = Object.assign({}, initialCompanyFormData());
      this.languages.forEach((language) => {
        TRANSLATED_ATRIBUTES.forEach((attribute) => {
          errorData["translations." + language.lang + "." + attribute] = "";
        });
      });
      this.messages = errorData;
    },

    inArray(array, item) {
      if (array.length == 0) return true;
      let res = array.find(
        (i) =>
          i.city == item.city &&
          i.country == item.country &&
          i.postcode == item.postcode &&
          i.street == item.street
      );

      return !res;
    },

    checkAddToShipping(item) {
      return this.inArray(this.formModel.addresses.shipping, item);
    },

    handleAddToShipping(item) {
      this.formModel.addresses.shipping.push(item);
    },

    checkAddToBilling(item) {
      return this.inArray(this.formModel.addresses.billing, item);
    },

    handleAddToBilling(item) {
      this.formModel.addresses.billing.push(item);
    },

    setError(prop, value) {
      // this.messages[prop] = value;
      // console.log("this.messages", this.messages);

      this.$set(this.messages, prop, value);
    },

    permissionCanCompany(action) {
      return this.permissions.find(
        (x) => x.name == "contacts.company." + action
      );
    },

    permissionCanContact(action) {
      return this.permissions.find(
        (x) => x.name == "contacts.contact." + action
      );
    },
  },
  async mounted() {
    // this.fetchCompanyType();
    // this.fetchIndustry();
    // this.fetchQualification();
    // this.fetchCompanyCategory();
    // this.fetchSupplierType();
    // this.fetchCountry();
    // this.setPermissions();
  },
};
</script>
