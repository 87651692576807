<template>
  <div class="text-center">
    <v-dialog
      v-if="dialogData"
      v-model="dialogData.show"
      width="960"
      persistent
      scrollable
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Contact Activity</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon dark @click="handleCloseModalForm()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-card-text class="mt-3">
          Contact details in AC:
          <a :href="contactDetailsAC" target="_blank">{{ contactDetailsAC }}</a>
          <v-timeline align="start" v-if="activities">
            <v-timeline-item
              v-for="(item, i) in activities"
              :key="i"
              :dot-color="item.color"
              :icon="item.icon"
              fill-dot
            >
              <v-card>
                <!-- <v-card-title :class="['text-h6', `bg-${item.color}`]"> -->
                <v-card-title :class="['text-h6']">
                  <!-- {{ item.referenceModelName }} -->
                  <span v-if="item.URL">
                    <a :href="item.URL" target="_blank">{{ item.title }}</a>
                  </span>
                  <span v-else>
                    {{ item.title }}
                  </span>
                </v-card-title>
                <v-card-text class="bg-white text--primary">
                  <ul style="list-style-type: none">
                    <li>{{ item.reference_action }}</li>
                    <!-- <li>Reference type: {{ item.reference_type }}</li> -->
                    <li>Created at: {{ item.created_at }}</li>
                    <!-- <v-btn :color="item.color" variant="outlined"> Button </v-btn> -->
                  </ul>
                </v-card-text>
              </v-card>
            </v-timeline-item>
          </v-timeline>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="handleCloseModalForm">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";

export default {
  name: "ACActivityDialog",

  props: ["dialogData"],

  data() {
    return {
      modalForm: null,
      activities: null,
      contactDetailsAC: null,
    };
  },

  watch: {
    "dialogData.contactID": {
      handler: function (val) {
        if (val) {
          this.fetchData();
        }
      },
    },
  },

  methods: {
    fetchData() {
      if (this.dialogData.contactID) {
        ApiService.get(
          `contact/contact/${this.dialogData.contactID}/acActivity`
          // `contact/contact/123/acActivity`
        ).then(({ data }) => {
          if (!data.activities) {
            Swal.fire({
              title: "AC",
              text: "AC data not found!",
              icon: "error",
            });
            this.handleCloseModalForm();
          } else {
            this.activities = data.activities
              .filter(
                (item) =>
                  item.reference_type != "SubscriberSeries" &&
                  item.reference_type != "EcomOrderActivity"
              )
              .map((item) => {
                item.created_at = new Date(item.tstamp).toLocaleString(
                  "hu-HU",
                  {
                    timeZone: "UTC",
                  }
                );
                // item.color = this.items[item.reference_id].color;
                // item.icon = this.items[item.reference_id].icon;

                switch (item.reference_type) {
                  case "Log":
                    item.color = "light-danger";
                    item.icon = "mdi-email-outline";
                    item.title = "Offer/order email sent";
                    break;
                  case "MppLinkData":
                    item.color = "light-success";
                    item.icon = "mdi-email-open-outline";
                    item.title = "Email opened";
                    break;
                  case "LinkData":
                    item.color = "light-success";
                    item.icon = "mdi-email-open-outline";
                    item.title = "Email opened";
                    break;
                  default:
                    item.color = "light-primary";
                    item.icon = "mdi-airballoon";
                    item.title = "Offer/order email sent";
                }

                // Log: {
                //   color: "light-success",
                //   icon: "mdi-email-outline",
                //   title: "Offer/order email sent",
                // },
                // MppLinkData: {
                //   color: "light-danger",
                //   icon: "mdi-email-open-outline",
                //   title: "Email opened",
                // },
                // LinkData: {
                //   color: "light-primary",
                //   icon: "mdi-email-open-outline",
                //   title: "Email opened",
                // },

                return item;
              });

            if (data.ecomOrderActivities) {
              let ecomOrderActivities = data.ecomOrderActivities.map((item) => {
                let externalid = item.externalid;
                item.color = "light-primary";
                item.icon = "mdi-cart-outline";
                item.title = item.orderNumber;

                item.URL = `/#/sales/salesorders/${externalid}`;

                item.created_at = new Date(item.createdDate).toLocaleString(
                  "hu-HU",
                  {
                    timeZone: "UTC",
                  }
                );
                return item;
              });

              this.activities = [...this.activities, ...ecomOrderActivities];
              this.activities.sort((a, b) =>
                a.created_at > b.created_at ? -1 : 1
              );

              let ACUserId = data.contacts[0].id;
              this.contactDetailsAC = `https://kismetal.activehosted.com/app/contacts/${ACUserId}`;
            }
            console.log(data);
          }
        });
      } else {
        this.SOEntityDialog = false;
      }
    },

    handleCloseModalForm() {
      this.activities = null;
      this.dialogData.contactID = null;
      this.$emit("handleCloseDialog");
    },
  },
};
</script>
